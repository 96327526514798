@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply overflow-hidden;
}

body * {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
